<template>
  <div class="card">
    <div class="card-body">
      <div>
        <a-page-header :title="$t('menu.preinscription')">
          <template slot="extra"> </template>
          <a-descriptions size="small" :column="3">
            <a-descriptions-item :label="$t('menu.eleves')">
              {{ filtredTable.length }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('liste.parents')">
              {{ parentsCount }}
            </a-descriptions-item>
          </a-descriptions>
          <a-row type="flex">
            <a-statistic
              :title="$t('liste.total')"
              :value="filtredTable.length"
            />
            <a-statistic
              :title="$t('liste.filles')"
              prefix="%"
              :value="countFille"
              :style="{
                margin: '0 32px',
              }"
            />
            <a-statistic
              :title="$t('liste.garcons')"
              prefix="%"
              :value="countGarcon"
            />
          </a-row>
        </a-page-header>
        <div></div>
        <div>
          <a-table
            @change="tableChanged"
            :loading="tableLeading"
            :pagination="true"
            :data-source="data"
            :columns="columns"
            :rowKey="'_id'"
          >
            <template slot="firstName" slot-scope="text, record">
              <div key="firstName">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.id, col)"
                />
                <template v-else>
                  <a-avatar
                    size="large"
                    style="color: #f56a00; background-color: #fde3cf"
                  >
                    {{ record.firstName[0].toUpperCase() }}
                  </a-avatar>
                  <a-button size="small" type="link" class="ml-2">
                    {{ text + " " + record.lastName }}
                  </a-button>
                </template>
              </div>
            </template>
            <template
              v-for="col in ['ficheparent']"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.id, col)"
                />
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <span slot="level" slot-scope="text">
              <a-tag :key="text" color="green">
                {{ levelLabes[text] }}
              </a-tag>
            </span>
            <a-button
              slot="ficheparent"
              slot-scope="text, record"
              @click="() => parentFile(record)"
            >
              {{ text }}
              <a-icon type="select" /> {{ $t("action.afficher") }}
            </a-button>

            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`${$t('recette.chercher')} ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
                >{{ $t("recette.chercher") }}</a-button
              >
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
                >{{ $t("action.reinitialiser") }}</a-button
              >
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>{{ text }}</template>
            </template>
            <template slot="delete" slot-scope="text, record">
              <a-button-group>
                <a-popconfirm
                  :title="$t('inscription.sureToInscri')"
                  @confirm="() => onInscri(record)"
                >
                  <a-button type="primary" size="small" icon="user">
                    {{ $t("inscription.inscription") }}
                  </a-button>
                </a-popconfirm>
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => onDelete(record)"
                >
                  <a-button type="danger" size="small" icon="delete">
                    {{ $t("action.supprimer") }}
                  </a-button>
                </a-popconfirm>
              </a-button-group>
            </template>
          </a-table>
        </div>
        <a-modal
          v-model="visibleParentFile"
          :title="$t('all.enterInformationBelow')"
          :width="1000"
          :key="componentKey"
        >
          <template slot="footer">
            <a-button key="back" @click="handleCancelModalParent">
              {{ $t("action.annuler") }}
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loadingData"
              :disabled="loadingData"
              @click="updateParent"
            >
              {{ $t("action.confirmer") }}
            </a-button>
          </template>
          <div>
            <span class="span">
              <label>
                {{ $t("liste.nomPere") }}
                <a-input
                  id="nomPere"
                  type="text"
                  name="nomPere"
                  :default-value="activeParent.father.firstName"
                  :disabled="activeParent.parentID"
                />
              </label>
              <label>
                {{ $t("liste.prenomPere") }}
                <a-input
                  id="prenomPere"
                  type="text"
                  name="prenomPere"
                  :default-value="activeParent.father.lastName"
                  :disabled="activeParent.parentID"
                />
              </label>
            </span>
            <span class="span">
              <label class="space">
                {{ $t("liste.nomMere") }}
                <a-input
                  id="nomMere"
                  class="inp"
                  type="text"
                  name="nomMere"
                  :default-value="activeParent.mother.firstName"
                  :disabled="activeParent.parentID"
                />
              </label>
              <label class="space">
                {{ $t("liste.prenomMere") }}
                <a-input
                  id="prenomMere"
                  class="inp"
                  type="text"
                  name="prenomMere"
                  :default-value="activeParent.mother.lastName"
                  :disabled="activeParent.parentID"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.telPere") }}
                <a-input
                  id="telephonePere"
                  type="text"
                  name="telephonePere"
                  :default-value="activeParent.father.phone"
                  :disabled="activeParent.parentID"
                />
              </label>
              <label class="space">
                {{ $t("liste.telMere") }}
                <a-input
                  id="telephoneMere"
                  class="inp"
                  type="text"
                  name="telephoneMere"
                  :default-value="activeParent.mother.phone"
                  :disabled="activeParent.parentID"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.metierPere") }}
                <a-input
                  id="FonctionPere"
                  type="text"
                  name="FonctionPere"
                  :default-value="activeParent.father.job"
                  :disabled="activeParent.parentID"
                />
              </label>
              <label class="space">
                {{ $t("liste.metierMere") }}
                <a-input
                  id="FonctionMere"
                  class="inp"
                  type="text"
                  name="FonctionMere"
                  :default-value="activeParent.mother.job"
                  :disabled="activeParent.parentID"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.emailParents") }}
                <a-input
                  id="emailParent"
                  type="text"
                  name="emailParent"
                  :default-value="activeParent.email"
                  :disabled="activeParent.parentID"
                />
              </label>
              <label class="space">
                {{ $t("liste.adresseParents") }}
                <a-input
                  id="adressParent"
                  class="inp"
                  type="text"
                  name="adressParent"
                  :default-value="activeParent.adress"
                  :disabled="activeParent.parentID"
                />
              </label>
            </span>
            <span class="span">
              <a-list
                :grid="{ gutter: 16, column: 3 }"
                item-layout="v"
                :data-source="activeStudentlist"
                :bordered="true"
              >
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <a-list-item-meta :description="item.description">
                    <a slot="title" :class="index">{{ item.title }}</a>
                    <a-avatar
                      size="large"
                      slot="avatar"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ item.title[0].toUpperCase() }}
                    </a-avatar>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </span>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import router from "@/router";
import $ from "jquery";
import apiClient from "@/services/axios";
import eventsLogger from "@/services/eventsLogger";
import JsPDF from "jspdf";
import { mapState } from "vuex";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import _ from "lodash";
import moment from "moment";

function isNotEmpty(str) {
  return str && str.length !== 0;
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function readbleDate(dateString) {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
}
function parentFormater(parent) {
  return {
    name:
      parent.father.firstName +
      " " +
      parent.father.lastName +
      " et " +
      parent.mother.firstName +
      " " +
      parent.mother.lastName,
    id: parent._id,
  };
}
function getStudents(item) {
  const students = [];
  for (const student of item.students) {
    student.BirthDate = readbleDate(student.BirthDate);
    student.createdAt = readbleDate(student.createdAt);
    student.preId = item._id;
    student.fullname = student.firstName + " " + student.lastName;
    students.push(student);
  }
  return students;
}

export default {
  created() {
    eventsLogger.addEvent("/api/log", { studen: "changed", by: "a user" });
    this.currentYear = this.settings.schoolarYears.filter(
      (sc) => sc._id == this.settings.activeSchoolarYear
    )[0].name;
    this.activeBuilding = this.settings.image.filter(
      (building) => building.dbName == this.settings.activeBuilding
    )[0];
    this.tableLeading = true;

    apiClient.get("/preinscription/").then((res) => {
      this.data = [];
      res.data.map((item) => this.data.push(...getStudents(item)));
      this.cacheData = this.data;
      this.tableLeading = false;
      this.filtredTable = this.data;

      this.parentsList = res.data.map((item) => parentFormater(item));
      this.parentsCount = res.data.length;
      this.preinscriptionData = res.data.map((item) => {
        for (let i = 0; i < item.students.length; i++) {
          item.students[i].preId = item._id;
        }
        return item;
      });
      console.log(res.data);
      console.log(this.preinscriptionData);
    });
  },
  data() {
    return {
      levelLabes: [
        this.$t("niveau.niveau0"),
        this.$t("niveau.niveau1"),
        this.$t("niveau.niveau2"),
        this.$t("niveau.niveau3"),
        this.$t("niveau.niveau4"),
        this.$t("niveau.niveau5"),
        this.$t("niveau.niveau6"),
        this.$t("niveau.autres"),
      ],
      componentKey: 0,
      disabledSP: false,
      activeBuilding: {},
      filtredTable: [],
      tableLeading: true,
      reductions: false,
      newStudentForm: this.$form.createForm(this),
      newStudent: {
        birthDate: null,
        gender: null,
        classRoom: null,
        parent: null,
      },
      newParent: false,
      addNewParent: false,
      parentsList: [],
      parentsCount: 0,
      preinscriptionData: [],
      activeParentChange: {},
      activeStudent: {},
      attestationPresenceBirthdate: "",
      activeParent: {
        father: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        mother: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        students: [
          {
            firstName: "",
            lastName: "",
            _id: "",
          },
        ],
      },
      activeStudentlist: [],
      editingKey: "",
      visibleParentFile: false,
      visibleAttestationPresence: false,
      data: [],
      cacheData: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      currentYear: "",
      value: 1,
      value1: 1,
      loadingData: false,
      visibleData: false,
      columns: [
        {
          title: this.$t("liste.nomPrenom"),
          dataIndex: "firstName",
          key: "firstName",
          width: 60,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("personnel.niveau"),
          dataIndex: "level",
          key: "level",
          width: 50,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "level",
          },
          onFilter: (value, record) => record.niveau.includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.ficheParent"),
          dataIndex: "ficheparent",
          key: "parent",
          width: 50,
          scopedSlots: {
            customRender: "ficheparent",
          },
        },
        {
          title: this.$t("liste.inscriSupprimer"),
          dataIndex: "delete",
          width: 210,
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
      ],
    };
  },
  computed: {
    ...mapState(["settings"]),
    countGarcon: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "male" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
    countFille: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "female" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
  },
  methods: {
    moment,
    debounceField: _.debounce(function (e) {
      let field = e.target.id;
      this.newStudentForm.setFieldsValue({
        [field]: e.target.value,
      });
      this.newStudentForm.validateFields([`${field}`]);
    }, 50),

    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    updateParent() {
      const id = this.activeParent._id;
      this.loadingData = true;
      const update = {
        father: {
          firstName: $("#nomPere").val(),
          lastName: $("#prenomPere").val(),
          job: $("#ProfessionPere").val(),
          phone: $("#telephonePere").val(),
        },
        mother: {
          firstName: $("#nomMere").val(),
          lastName: $("#prenomMere").val(),
          job: $("#professionMere").val(),
          phone: $("#telephoneMere").val(),
        },
        email: $("#emailParent").val(),
        adress: $("#adressParent").val(),
      };
      apiClient
        .patch(`/preinscription/${id}`, { ...update })
        .then((res) => {
          this.preinscriptionData = this.preinscriptionData.map((p) => {
            if (p._id == id)
              p = {
                ...p,
                ...update,
              };
          });
          this.parentsList = this.parentsList.map((parent) =>
            parent._id == id ? parentFormater({ ...update, _id: id }) : parent
          );
          this.visibleParentFile = false;
          this.loadingData = false;
          location.reload();
        })
        .catch(() => {
          this.visibleParentFile = false;
          this.loadingData = false;
          this.openNotificationWithIcon(
            "warning",
            this.$t("liste.changementNonEffectue"),
            this.$t("liste.champsVides")
          );
        });
    },
    triggerAddNewParent() {
      this.disabledSP = !this.disabledSP;
      this.newParent = !this.newParent;
    },
    handleNewStudentParentOnChange(value) {
      this.newStudent.parent = value;
    },
    handleNewStudentDateOnChange(value) {
      this.newStudent.birthDate = value;
    },
    handleNewStudentClassRoomOnChange(value) {
      this.newStudent.classRoom = value;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    parentFile(record) {
      this.componentKey += 1;
      this.activeParent = this.preinscriptionData.find(
        (d) => d._id == record.preId
      );
      this.activeStudentlist = this.activeParent.students.map((student) => {
        return {
          title: student.firstName + " " + student.lastName,
        };
      });

      this.visibleParentFile = true;
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
        this.cacheData = this.data;
      }
    },

    async onInscri(record) {
      const currentParent = this.preinscriptionData.find(
        (s) => record.preId == s._id
      );
      const parentData = JSON.parse(JSON.stringify(currentParent));
      const studentData = JSON.parse(JSON.stringify(record));

      delete studentData["_id"];
      delete studentData["preId"];
      delete studentData["createdAt"];
      delete studentData["registered"];
      delete studentData["level"];
      delete studentData["fullname"];

      delete parentData["_id"];
      delete parentData["updatedAt"];
      delete parentData["createdAt"];
      delete parentData["__v"];
      delete parentData["students"];
      delete parentData["parentID"];

      studentData.schoolarYearsHistory = {};
      studentData.schoolarYearsHistory[this.settings.activeSchoolarYear] = null;

      if (!parent.parentID) {
        await apiClient.put("/parents", { data: parentData }).then((res) => {
          const { parent } = res.data;
          studentData.parent = parent._id;
          currentParent.parentID = parent._id;
          apiClient.patch(`/preinscription/${record.preId}`, {
            parentID: parent._id,
          });
        });
      } else {
        studentData.parent = currentParent.parentID;
      }

      await apiClient
        .put("/students", studentData)
        .then((res) => {
          studentData._id = res.data._id;
          this.$message.success(this.$t("success.eleveAjout"));
          this.onDelete(record, false);
        })
        .catch((e) => {
          console.error(e);
          this.$message.warning(this.$t("warning.connectionProblem"));
        });
    },
    onDelete(record, notifi = true) {
      let parent = this.preinscriptionData.find((s) => record.preId == s._id);
      parent.students = parent.students.filter((s) => s._id != record._id);

      if (parent.students.length > 0) {
        apiClient
          .delete(`/preinscription/${record.preId}/${record._id}`)
          .then((res) => {
            const dataSource = [...this.data];
            this.data = dataSource.filter((item) => item._id !== record._id);
            this.cacheData = this.data;
            if (notifi)
              this.openNotificationWithIcon(
                "success",
                this.$t("success.eleveSupp2")
              );
          })
          .catch((e) => {
            if (notifi) this.$message.warning(this.$t("error.supp"));
          });
      } else {
        apiClient
          .delete(`/preinscription/${record.preId}`)
          .then((res) => {
            const dataSource = [...this.data];
            this.data = dataSource.filter((item) => item._id !== record._id);
            this.cacheData = this.data;
            if (notifi)
              this.openNotificationWithIcon(
                "success",
                this.$t("success.eleveSupp2")
              );
          })
          .catch((e) => {
            if (notifi)
              if (notifi) this.$message.warning(this.$t("error.supp"));
          });
      }
    },

    handleCancelModalAdd(e) {
      this.visibleData = false;
      this.addNewParent = false;
    },
    handleCancelModalParent(e) {
      this.visibleParentFile = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    showModal() {
      this.visibleData = true;
      this.newStudentForm.resetFields();
    },
    onChange(e) {
      this.newStudent.gender = e.target.value;
    },
    onChangeParent(e) {},
    openNotificationWithIcon(type, msg, desc) {
      notification[type]({
        message: msg,
        description: desc,
      });
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
