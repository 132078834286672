/* eslint-disable */
import apiClient from '../axios'

class eventsLogger {
    
    constructor() {
    }
   
    addEvent(to,data){
        
    }
  }
  export default new eventsLogger();